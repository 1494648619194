<template>
    <div>
        <div class="row" v-if="btnIndex == 1">
            <div class="col-12 col-lg-7">
                <b-card :header="$t('course_detail_title')" class="soft">
                    <div class="row">
                        <div class="col-12">
                            <b-form-group :label="$t('faculty')">
                                {{ viewData.faculty_name }}
                            </b-form-group>
                        </div>

                        <div class="col-12">
                            <b-form-group :label="$t('program')">
                                {{ viewData.program_name }}
                            </b-form-group>
                        </div>

                        <div class="col-6">
                            <b-form-group :label="$t('course_code')">
                                {{ getObjectValue(viewData, 'course_code') }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6">
                            <b-form-group :label="$t('course_name')">
                                {{ viewData.course_name }}
                            </b-form-group>
                        </div>
                        <div class="d-flex col-12" style="gap:16px">
                            <b-form-group :label="$t('credit')">
                                {{ getObjectValue(viewData, 'credit') }}
                            </b-form-group>

<!--                            <b-form-group :label="$t('theoretical')">-->
<!--                                {{ getObjectValue(viewData, 'theoretical_credit') }}-->
<!--                            </b-form-group>-->

<!--                            <b-form-group :label="$t('practical')">-->
<!--                                {{ getObjectValue(viewData, 'practical_credit') }}-->
<!--                            </b-form-group>-->
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group :label="$t('section')">
                                <b-form-select v-model="section"
                                               :options="sectionOptions"/>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group :label="$t('semester')">
                                <semesters-selectbox v-model="semester"/>
                            </b-form-group>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-lg-5">
                <b-card :header="$t('staff')" class="soft">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <b-form-group :label="$t('teaching_staff')">
                            {{ getObjectValue(viewData, 'instructor') }}
                        </b-form-group>
                    </div>
                </b-card>
                <b-card :header="$t('processes')" class="soft">
                    <b-card-body class="d-flex flex-column flex-sm-row flex-wrap" style="gap:8px">
                        <b-button variant="outline-primary" style="line-height:1" @click="studentListWithPhoto">
                            {{ $t('student_list_with_photo') }}
                        </b-button>
                        <b-button variant="outline-primary" style="line-height:1" @click="attendanceEntry">{{
                                $t('prep_attendance')
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="gradeEntry">{{
                                $t('grade_entry')
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="studentList">{{
                                $t('student_list')
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="openDocuments">{{
                                $t('documents')
                            }}
                        </b-button>
                    </b-card-body>
                </b-card>
            </div>
        </div>
        <students :courseId="id"
                  :semesterId="semester"
                  :sectionNo="sectionNo"
                  @back="courseDetails"
                  v-if="btnIndex == 2"/>
        <students-with-photo :courseId="id"
                             :semesterId="semester"
                             :sectionNo="sectionNo"
                             :coverLetter="viewData"
                             @back="courseDetails"
                             v-if="btnIndex == 3"/>
        <CommonModal ref="documents" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>{{ $t('documents') }}</template>
            <template v-slot:CommonModalContent v-if="process=='documents'">
                <b-row v-if="documents && documents.length>0">
                    <b-col cols="12" md="6"
                           v-for="(document, index) in documents"
                           :key="index"
                    >
                        <b-button-group class="mt-2 w-100">
                            <b-button v-for="(lang, langKey) in document.language" :key="'lang'+langKey"
                                variant="outline-dark" @click="document.callback(lang)">
                                {{ document.text }}
                            </b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import SectionService from "@/services/SectionService";
import Students from "./Students"
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import StudentsWithPhoto from "./StudentsWithPhoto";
import CommonModal from "@/components/elements/CommonModal.vue";
import i18n from "@/plugins/i18n";

import StudentDocumentService from "@/services/StudentDocumentService";
import StudentCourseService from "@/services/StudentCourseService";

import {EventBus} from "@/main";
import showErrors from "@/helpers/showErrors";


export default {
    components: {
        CommonModal,
        Students,
        SemestersSelectbox,
        StudentsWithPhoto
    },
    props: {
        id: {
            default: null,
            type: Number
        },
        semester_id:{
            default: null,
            type:String
        }
    },
    data() {
        return {
            viewData: [],
            section: null,
            sectionOptions: [],
            btnIndex: 1,
            semester: null,
            sectionNo: null,
            process: null,
            documents: [

                {
                    text: i18n.t('attendance_list'),
                    class: 'ri-article-line',
                    permission: 'studentdocument_attendancelist',
                    language: ['tr'],
                    callback: (lang) => {
                        if (this.section != null) {
                            StudentDocumentService.downloadAttendanceList(this.section)
                                .then(response => {
                                    let fileName = i18n.t('print_roster') + '-' + lang + '.pdf'
                                    EventBus.$emit('pdfViewerModalShow', {
                                        pdfFileData: response.data,
                                        pdfFileName: fileName
                                    });
                                })
                                .catch(e => {
                                    showErrors(e, null, true)
                                })
                        } else {
                            this.$toast.error(this.$t('entry_section'))
                        }
                    }
                },
                {
                    text: i18n.t('print_weekly_roste'),
                    class: 'ri-article-line',
                    language: ['tr'],
                    callback: (lang) => {
                        if (this.section != null) {
                            StudentDocumentService.downloaWeeklydAttendanceList(this.section)
                                .then(response => {
                                    let fileName = i18n.t('print_weekly_roste') + '-' + lang + '.pdf'
                                    EventBus.$emit('pdfViewerModalShow', {
                                        pdfFileData: response.data,
                                        pdfFileName: fileName
                                    });
                                })
                                .catch(e => {
                                    showErrors(e, null, true)
                                })
                        } else {
                            this.$toast.error(this.$t('entry_section'))
                        }
                    }
                },

                {
                    text: i18n.t('excel'),
                    class: 'ri-article-line',
                    permission: 'studentcourse_instructorenteredlettergradeexcelexport',
                    language: ['tr'],
                    callback: () => {
                            StudentCourseService.instructorLetterGradeExcelReport(this.id)
                                .then(res => {
                                    this._downloadFile(res,this.$t('instructor_letter_grade_excel_export')+'.xlsx')
                                })
                                .catch(e => {
                                    showErrors(e, null)
                                })
                        }
                    }
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {
        getData(courseId) {
            this.semester=this.semester_id
            this.btnIndex = 1
            this.sectionOptions = []
            const config = {
                params: {
                    course_id: courseId
                },
            }

            SectionService.instructorSections(config).then(response => {
                this.viewData = response.data.data[0]
                response.data.data.forEach(item => {
                    this.sectionOptions.push(
                        {
                            value: item.section_id,
                            text: item.section
                        })
                    this.section=this.viewData.section_id
                })
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message))
            })

        },

        gradeEntry() {
            this.$router.push('/grade/entry/' + this.id)
        },

        attendanceEntry() {
            if (this.section != null) {
                this.$router.push('/attendance/entry/' + this.section)
            } else {
                this.$toast.error(this.$t('section') + ' ' + this.$t('select'))
            }
        },

        studentList() {
            if (this.semester != 0 && this.section != null) {
                this.sectionOptions.forEach((item) => {
                    if (item.value == this.section) {
                        this.sectionNo = item.text
                    }
                })
                this.btnIndex++

            } else {
                this.$toast.error(this.$t('semester') + ', ' + this.$t('section') + ' ' + this.$t('select'))
            }
        },

        studentListWithPhoto() {
            if (this.semester != 0 && this.section != null) {
                this.sectionOptions.forEach((item) => {
                    if (item.value == this.section) {
                        this.sectionNo = item.text
                    }
                })
                this.btnIndex = 3

            } else {
                this.$toast.error(this.$t('semester') + ', ' + this.$t('section') + ' ' + this.$t('select'))
            }
        },

        courseDetails() {
            this.btnIndex = 1
        },
        openDocuments() {
            this.process = 'documents';
            this.showModal();
        },
        formClear() {
            this.process = null;
            this.hideModal();
        },
        showModal() {
            this.$refs.documents.$refs.commonModal.show()
        },
        hideModal() {
            this.$refs.documents.$refs.commonModal.hide()
        }

    },

    created() {
        this.getData(this.id)
    },

    watch: {
        id: {
            handler: function (val) {
                this.getData(val)
            }
        }
    }
};
</script>

